<template>
	<div class="activity-details">
		<div class="activity-details__navbar">
			<Navbar />
		</div>
		<div class="activity-details__body">
			<div class="activity-details__body-top">
				<ActivityDetailsMain :defaultPhoto="defaultPhoto" :activity="activity" :activityTheme="activityTheme" />
			</div>
			<div class="activity-details__body-bottom">
				<div class="activity-details__body-bottom-left" style="width: 66%">
					<ActivityDetailsDescription :activity="activity" :defaultPhoto="defaultPhoto" />
					<ActivityDetailsRelatedActivities :activities="activities" />
				</div>
				<div class="activity-details__body-bottom-right" style="width: 34%; padding-left: 2.5rem">
					<ActivityDetailsParticipants :activity="activity" />
				</div>
			</div>
		</div>
		<footer class="activity-details__footer">
			<Footer />
		</footer>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';
import { getDocument } from '@/api/common.js';
import { getActivity, getActivityTheme } from '@/api/activity.js';
import { search, saveLogsAccess, receiveDateNow } from '@/api/common.js';

import ActivityDetailsMain from '@/components/activities/ActivityDetailsMain.vue';
import ActivityDetailsDescription from '@/components/activities/ActivityDetailsDescription.vue';
import ActivityDetailsParticipants from '@/components/activities/ActivityDetailsParticipants.vue';
import ActivityDetailsRelatedActivities from '@/components/activities/ActivityDetailsRelatedActivities.vue';

export default {
	name: 'ProductDetails',
	title() {
		return `${this.$t('activities.headerTitle')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		ActivityDetailsMain,
		ActivityDetailsDescription,
		ActivityDetailsParticipants,

		ActivityDetailsRelatedActivities,
		Footer
	},
	data() {
		return {
			activity: {},
			activities: {},
			defaultPhoto: null,
			activityTheme: {}
		};
	},
	mounted() {
		this.getDefaultPhoto();
		this.getActivity();
		this.getActivities();

		const user = JSON.parse(localStorage.getItem('pui9_session'));

		saveLogsAccess({
			loacfecha: receiveDateNow(),
			loacusr: user ? user.usr : 'anonymous',
			loacfuncionalidad: "Detalle de actividad",
			loaceventgroupid: this.$store.state.eventInfo.eveneventgroupid,
			loacurl: this.$route.path,
			loacip: user ? user.lastLoginIp : '0.0.0.0',
			loacenvironment: "CATÁLOGO",
			loacproductserviceid: 0,
			loacexhibitorid: 0
		});
	},

	methods: {
		async getActivity() {
			const id = this.$route.params.id;
			let data = await getActivity(id);
			this.activity = data;

			this.activityTheme = await getActivityTheme(this.activity.paacparallelactivitythemeid);
			console.log(this.activityTheme);
		},

		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},
		async getActivities() {
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'evenid',
							op: 'eq'
						}
					]
				},
				model: 'parallelactivity',
				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['name'],
				queryText: '',
				rows: 999999999
			};

			const data = await search(body);
			this.activities = data.data;
			console.log(this.activities);
		}
	},
	watch: {
		$route(to, from) {
			if (to !== from) {
				location.reload();
			}
		},

		'$store.state.activeLanguage'(newVal) {
			if (newVal) {
				this.getActivity();
				this.getActivities();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.activity-details {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__footer {
		flex: none;
	}
	&__body {
		overflow-y: hidden;
		flex: auto;
	}
}

.activity-details__body {
	padding: 1.75rem 8.125rem;
	padding-bottom: 4rem;

	&-bottom {
		display: flex;
	}
}
</style>
