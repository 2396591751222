import { render, staticRenderFns } from "./ActivityDetailsMain.vue?vue&type=template&id=702033de&scoped=true"
import script from "./ActivityDetailsMain.vue?vue&type=script&lang=js"
export * from "./ActivityDetailsMain.vue?vue&type=script&lang=js"
import style0 from "./ActivityDetailsMain.vue?vue&type=style&index=0&id=702033de&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702033de",
  null
  
)

export default component.exports