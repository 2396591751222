<template>
	<div>
		<div class="activity-details-main__container-middle">
			<iframe
				v-if="activity.paarvideo && embedVideo(activity.paarvideo)"
				:src="embedVideo(activity.paarvideo)"
				width="889"
				height="500"
				frameborder="0"
				allow="fullscreen"
				style="border-radius: 8px"
			></iframe>
			<v-carousel v-else-if="carousel" hide-delimiter-background style="border-radius: 8px">
				<v-carousel-item v-for="item in carousel" :key="item.id" :src="item.url"></v-carousel-item>
			</v-carousel>
			<img v-else-if="photo" class="activity-details-main__container-middle-image" :src="photo.url" alt="foto" />
			<img v-else class="activity-details-main__container-middle-image" :src="defaultPhoto" alt="foto" />
		</div>
		<div v-if="activity.patrdescrip" class="activity-details-description">
			<p v-html="activity.patrdescrip" class="activity-details-description-text"></p>
		</div>
	</div>
</template>

<script>
import { getDocument } from '@/api/common.js';

export default {
	name: 'ActivityDetailsDescription',

	props: {
		activity: {},
		defaultPhoto: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			carousel: null,
			photo: null
		};
	},
	mounted() {
		this.getPhoto();
	},

	methods: {
		getPhoto: async function () {
			const id = this.$route.params.id;
			const data = await getDocument('parallelactivity', id, 'parallelactivity_primary');
			if (data.length) this.photo = data[0];
			this.getCarousel();
		},

		getCarousel: async function () {
			const id = this.$route.params.id;
			const data = await getDocument('parallelactivity', id, 'gallery');
			if (data.length) {
				this.carousel = data;
				if (this.photo) {
					this.carousel.unshift(this.photo);
				}
			}
		},
		embedVideo: function (url) {
			const urlSplitted = url.split('/');
			const host = urlSplitted[2];
			if (host === 'www.youtube.com') {
				return 'https://www.youtube.com/embed/' + this.getYoutubeId(url);
			} else if (host === 'vimeo.com') {
				return 'https://player.vimeo.com/video/' + urlSplitted[urlSplitted.length - 1];
			} else return null;
		},

		getYoutubeId: function (url) {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			const match = url.match(regExp);

			return match && match[2].length === 11 ? match[2] : null;
		}
	},
	components: {}
};
</script>


<style lang="scss" scoped>
.activity-details-description {
	&-text {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 33px;
		/* or 165% */

		letter-spacing: -0.15px;

		/* Neutral / Dark grey */

		padding: 1rem 0;
		color: #575e60;
	}
}

.activity-details-main__container {
	&-middle {
		padding: 1.5rem 0;
		display: flex;
		justify-content: center;

		&-image {
			border-radius: 8px;
			width: 100%;
			max-height: 31.25rem;
			object-fit: cover;
		}
	}
}
</style>
