<template>
	<div class="activity-details-main">
		<div class="activity-details-main__breadcrums">
			<router-link :to="{ name: 'Home' }" class="activity-details-main__breadcrums-link">{{ $t('router.home') }}</router-link>
			<span class="activity-details-main__breadcrums-separator">/</span>
			<router-link :to="{ name: 'Activities' }" class="activity-details-main__breadcrums-link">{{ $t('exhibitors.activities') }}</router-link>
		</div>
		<div class="activity-details-main__container">
			<div class="activity-details-main__container-top">
				<div class="activity-details-main__container-top-left">
					<div class="activity-details-main__container-top-left-logo">
						<img class="activity-details-main__container-top-left-logo-image" :src="defaultPhoto" alt="logo" />
					</div>
					<div class="activity-details-main__container-top-left-text">
						<h1 class="activity-details-main__container-top-left-text-title">{{ activity.patrname }}</h1>

						<h2 v-if="activity.paarlocation" class="activity-details-main__container-top-left-text-subtitle">
							{{ getFullDatePretty(activity.paacdatetimeini, activity.paacdatetimeend) }} - {{ activity.paarlocation }}
						</h2>
						<h2 v-else class="activity-details-main__container-top-left-text-subtitle">
							{{ getFullDatePretty(activity.paacdatetimeini, activity.paacdatetimeend) }}
						</h2>
						<div class="activity-details-main__container__card-right-badges">
							<div
								class="activity-details-main__container__card-right-badges-item"
								:style="`background-color: ${HEXtoRGBA(activityTheme.pathcolor, 20)};
										border: 1px solid ${activityTheme.pathcolor};
										color: ${activityTheme.pathcolor};`"
							>
								{{ activityTheme.pttrname }}
							</div>
						</div>
					</div>
				</div>
				<div class="activity-details-main__container-top-right">
					<!-- <Modal :product="activity" favblue /> -->

					<button
						class="activity-details-main__container-top-right-button activity-details-main__container-top-right-button--white"
						@click="
							createCalendar(
								activity.patrname,
								activity.patrdescrip,
								activity.paarlocation,
								activity.paacdatetimeini,
								activity.paacdatetimeend,
								actualURL,
								$store.state.eventInfo.evtrname
							)
						"
					>
						<img :src="IconCalendar" alt="appointment" />
					</button>

					<ShareDropdown outlined />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ShareDropdown from '@/components/common/ShareDropdown.vue';
// import Modal from '@/components/common/Modal.vue';
// import { getSectorName } from '@/api/sector.js';

export default {
	name: 'ActivityDetailsMain',

	props: {
		activity: {},
		activityTheme: {},
		defaultPhoto: {
			type: String,
			default: null
		}
	},

	components: {
		ShareDropdown
		// Modal
	},

	data() {
		return {
			IconCalendar: require('@/assets/icons/calendar-black.svg'),
			actualURL: window.location.href
		};
	},

	methods: {
		HEXtoRGBA: function (hex, opacity) {
			hex = hex.replace('#', '');
			const r = parseInt(hex.substring(0, 2), 16);
			const g = parseInt(hex.substring(2, 4), 16);
			const b = parseInt(hex.substring(4, 6), 16);
			return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
		},
		getFullDatePretty: function (dateini, dateend) {
			const dini = new Date(dateini);
			const dend = new Date(dateend);
			const optionsini = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
			const optionsend = { hour: '2-digit', minute: '2-digit' };
			let prettier = dini.toLocaleDateString(undefined, optionsini) + 'h';
			if (dateend) prettier = prettier + ' - ' + dend.toLocaleTimeString(undefined, optionsend) + 'h';
			return prettier;
		},

		createCalendar: function (title, descr, location, dateini, dateend, url, organizer) {
			this.$ics.addEvent(title, title, location, new Date(dateini), new Date(dateend), url, { name: organizer });
			this.$ics.download(title);
			this.$ics.removeAllEvents();
		}
	},

	computed: {}
};
</script>


<style lang="scss" scoped>
.icon {
	float: right;
}

.activity-details-main__breadcrums {
	padding-bottom: 0.813rem;

	&-link {
		color: #575e60;
		text-decoration: none;
	}

	&-separator {
		padding: 0 0.75rem;
	}
}

.activity-details-main__container {
	&-top {
		display: flex;
		justify-content: space-between;

		&-left {
			display: flex;
			align-items: center;

			&-logo {
				display: flex;
				align-items: center;
				padding-right: 1.5rem;

				&-image {
					width: 5.875rem;
					max-height: 4rem;
					object-fit: cover;
				}
			}

			&-text {
				&-title {
					font-family: Cabin;
					font-weight: bold;
					font-size: 32px;
					color: #293133;
				}

				&-subtitle {
					font-weight: normal;
					font-size: 14px;
					color: #575e60;
				}
			}
		}

		&-right {
			display: flex;
			align-items: center;

			&-button {
				border-radius: 8px;
				padding: 0.75rem;
				max-height: 2.5rem;
				display: flex;
				align-items: center;
				margin: 0 0.25rem;

				&--blue {
					background-color: #2498ff !important;
					font-family: Cabin;
					font-size: 1rem;
					color: #fff;
				}

				&--white {
					border: 1px solid #d2d7d9;
					font-family: Cabin;
					font-weight: bold;
					font-size: 1rem;
					color: #575e60;
				}
			}
		}
	}

	&-middle {
		padding: 1.875rem 0;
		display: flex;
		justify-content: center;

		&-image {
			border-radius: 8px;
			width: 100%;
			aspect-ratio: 16/9;
			object-fit: cover;
		}
	}
}

.activity-details-main__container__card {
	display: flex;
	padding-bottom: 1.5rem;

	&-right {
		padding-left: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;

		&-badges {
			padding-top: 0.5rem;
			display: flex;
			text-align: center;
			&-item {
				border-radius: 6px;
				padding: 0.125rem 0.375rem;
				font-weight: 500;
				font-size: 12px;
				margin-right: 0.5rem;
			}
		}
	}
}
</style>
