<template>
	<div v-if="activity.patrspeakers || activity.paarlocation" class="activity-details-features">
		<div class="activity-details-features-section" v-if="activity.patrspeakers">
			<span class="activity-details-features-titol">{{ $t('activities.participants') }}</span>
			<div class="activity-details-features-container">
				<span
					style="margin-bottom: none !important"
					v-html="activity.patrspeakers"
					class="activity-details-features-container-content"
				></span>
			</div>
		</div>
		<div class="activity-details-features-section" v-if="activity.paarlocation">
			<span class="activity-details-features-titol">{{ $t('activities.location') }}</span>

			<div class="activity-details-features-container">
				<span v-html="activity.paarlocation" class="activity-details-features-container-content"></span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ActivityDetailsFeatures',

	props: {
		activity: {}
	},

	components: {}
};
</script>


<style lang="scss" scoped>
.activity-details-features {
	background: #f3f5f5;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	padding: 1.25rem;
	margin: 1.5rem 0;

	&-section {
		display: flex;
		flex-direction: column;
	}

	&-titol {
		font-family: Cabin;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		color: #293133;
		padding-bottom: 16px;
	}

	&-container {
		display: flex;
		flex-direction: column;

		&-content {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 21px;
			color: #575e60;
			margin: 0 !important;
		}
	}
}
</style>
