import Pui9HttpRequests from 'pui9-requests';

export async function getActivity(id) {
	const controller = `/parallelactivity/get?paacid=${id}`;
	let data = [];

	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function listActivityThemes(body) {
	const controller = `/parallelactivitytheme/list`;
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		body,
		response => {
			if (response && response.data) {
				data = response.data.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}



export async function getActivityTheme(id) {
	const controller = `/parallelactivitytheme/get?pathid=${id}`;
	let data = [];

	await Pui9HttpRequests.getRequest(
		controller,
		null,
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		},
		{
			workingRole: 'ADMIN'
		}
	);

	return data;
}