<template>
	<div>
		<div class="activity-details-activity__top">
			<div class="activity-details-activity__top-title">
				<img :src="IconSimilar" alt="icon" />
				<h3 class="activity-details-activity__top-title-text">{{ $t('activities.relatedActivities') }}</h3>
			</div>
			<div class="activity-details-activity__top-pagination">
				<button @click="goPrev()" class="activity-details-activity__top-pagination-button">
					<i :class="current === 1 ? 'fal fa-chevron-left' : 'fas fa-chevron-left'"></i>
				</button>
				<button @click="goNext()" class="activity-details-activity__top-pagination-button">
					<i :class="current === Math.ceil(activities.length / pageSize) ? 'fal fa-chevron-right' : 'fas fa-chevron-right'"></i>
				</button>
			</div>
		</div>
		<div v-if="activities && activities.length" class="activities-interests__row">
			<div v-for="item in paginated" :key="item.paacid" class="activities-interests__item">
				<div class="activities-interests__item-top" style="position: relative">
					<div class="activities-interests__item-top-image">
						<router-link :to="{ name: 'ActivitiesDetails', params: { id: item.paacid } }" style="text-decoration: none">
							<div style="position: relative; left: 0; top: 0">
								<img v-if="item.imageurl" class="activities-interests__item-top-image-img" :src="item.imageurl" alt="photo" />
								<img v-else class="activities-interests__item-top-image-img" :src="defaultPhoto" alt="photo" />
								<img
									v-if="item.paarvideo"
									:src="IconPlay"
									alt="icon"
									style="position: absolute; top: calc(50% - 28px); left: calc(50% - 25.5px)"
								/>
							</div>
						</router-link>
					</div>
					<!-- <div style="position: absolute; right: 0; margin-right: 0.625rem; top: 0; margin-top: 0.6rem">
						<div class="activities-interests__item-top-image-buttons">
							<img :src="IconFav" alt="favorite" />
						</div>
					</div> -->
				</div>
				<div class="activities-interests__item-bottom">
					<router-link :to="{ name: 'ActivitiesDetails', params: { id: item.paacid } }" style="text-decoration: none">
						<span class="activities-interests__item-bottom-title">{{ item.patrname }}</span>
					</router-link>
					<span class="activities-interests__item-bottom-date">{{ getFullDatePretty(item.paacdatetimeini, item.paacdatetimeend) }} </span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getDocument } from '@/api/common.js';
import _ from 'lodash';

export default {
	name: 'ActivityDetailsRelatedActivities',
	props: {
		activities: {
			type: [Array, Object],
			required: true
		}
	},
	data() {
		return {
			IconFav: require('@/assets/icons/star-white.svg'),
			IconShare: require('@/assets/icons/share-white.svg'),
			IconSimilar: require('@/assets/icons/art.svg'),
			IconPlay: require('@/assets/icons/play.svg'),
			defaultPhoto: null,
			ascending: true,
			sortBy: 'name',
			current: 1,
			pageSize: 3
		};
	},
	components: {},
	mounted() {
		this.getDefaultPhoto();
	},
	methods: {
		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},
		getShareURL: function (productId) {
			return window.location.origin + this.$router.resolve({ name: 'ProductDetails', params: { id: productId } }).href;
		},

		goPrev() {
			if (this.current > 1) this.current--;
		},
		goNext() {
			if (this.current < Math.ceil(this.activities.length / this.pageSize)) this.current++;
		},
		getFullDatePretty: function (dateini, dateend) {
			const dini = new Date(dateini);
			const dend = new Date(dateend);
			const optionsini = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
			const optionsend = { hour: '2-digit', minute: '2-digit' };
			let prettier = dini.toLocaleDateString(undefined, optionsini) + 'h';
			if (dateend) prettier = prettier + ' - ' + dend.toLocaleTimeString(undefined, optionsend) + 'h';
			return prettier;
		}
	},
	computed: {
		indexStart() {
			return (this.current - 1) * this.pageSize;
		},
		indexEnd() {
			return this.indexStart + this.pageSize;
		},
		paginated() {
			return this.activities.slice(this.indexStart, this.indexEnd);
		},
		activitiesChunks() {
			return _.chunk(Object.values(this.activities), this.rowSize);
		}
	},
	watch: {
		$route(to, from) {
			if (to !== from) {
				location.reload();
			}
		}
	}
};
</script>


<style lang="scss" scoped>
.activity-details-activity__top {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&-title {
		display: flex;
		padding-bottom: 0.812rem;

		&-text {
			padding-left: 0.75rem;
			font-family: Cabin;
			font-weight: bold;
			font-size: 26px;
			color: #293133;
		}
	}

	&-pagination {
		&-button {
			padding: 0 0.5rem;
		}
	}
}

.activities-interests {
	padding: 2.5rem 15%;

	&__row {
		display: flex;
	}

	&__item {
		width: 31%;

		display: flex;
		flex-direction: column;
		margin: 0 0.5rem;

		&-top {
			&-image {
				height: 9.5rem;
				width: 100%;

				&-img {
					width: 100%;
					height: 9.5rem;
					object-fit: cover;
					border-radius: 8px;
				}

				&-buttons {
					display: flex;
				}
			}
		}

		&-bottom {
			display: flex;
			flex-direction: column;
			margin-top: 14px;

			&-title {
				font-family: Cabin;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 19px;

				/* Neutral / Extra-dark grey */

				color: #293133;
			}
			&-date {
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 21px;
				/* identical to box height, or 150% */
				margin-top: 3px;
				/* Neutral / Medium-dark grey */

				color: #868c8d;
			}
		}
	}
}
</style>
